import { PATH_APP } from "./paths";
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthProtect from "components/auth/AuthProtect";
import Dashboard from "layouts/admin";

const AppRoutes = {
  path: "*",
  guard: AuthProtect,
  layout: Dashboard,
  currentAdminUserGuard: true,
  routes: [
    {
      exact: true,
      path: "/admin/dashboard",
      component: lazy(() => import("views/admin/dashboards/default")),
    },
    {
      exact: true,
      path: PATH_APP.blueprints.root,
      component: lazy(() => import("views/blueprints/")),
    },
    {
      exact: true,
      path: PATH_APP.cards.root,
      component: lazy(() => import("views/cards")),
    },
    {
      exact: true,
      path: PATH_APP.templateUpload.root,
      component: lazy(() => import("views/templateUploads")),
    },
    {
      exact: true,
      path: PATH_APP.aiSystemPersonas.root,
      component: lazy(() => import("views/aiSystemPersonas")),
    },
    {
      exact: true,
      path: PATH_APP.aiChatSystemConfig.root,
      component: lazy(() => import("views/aiChatSystemConfig")),
    },
    {
      component: () => <Redirect to="/admin/dashboard" />,
    },
  ],
};

export default AppRoutes;
